import React, {useEffect, useRef} from 'react'
import {CoachCam} from "./CoachCam";
// import {useAppDispatch} from "../../redux/hooks";
import {SidePanel} from "./SidePanel";
import {CoachMainPane} from "./CoachMainPane";
// import {Button} from "@mui/material";
// import {returnToPreviousMode} from "../modes/modesSlice";
import {TitleBar} from "./TitleBar";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
    getAccessZoneTargetReviewRequestThunk,
    getPlayerProfileThunk,
    newV2Review,
    ReviewToolViewMode,
    updateLastReviewRequestId,
    VideoType,
    VideoUpdate
} from "./reviewtoolSlice";
import {RootState} from "../../redux/store";
import {ReviewRequestObject} from "../access-zone/accessZoneSlice";
import {SubmissionDetails} from "./SubmissionDetails";
import {updateIntroBackgroundByHandle} from "../../lib/recordingCanvas";
import {ViaUserProfile} from "../user/userSlice";
import {HLSVideoPlayer} from "../../shared/HLSVideoPlayer";

export interface FullScreenReviewToolProps {
    viewMode: ReviewToolViewMode
}


export function FullScreenReviewTool(props: FullScreenReviewToolProps){

    // const navigate = useNavigate();

    // const dispatch = useAppDispatch()
    const {review_request_id} = useParams<string>()
    console.log("id", review_request_id)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if(review_request_id){
            console.log("get that review_request!")
            dispatch(getAccessZoneTargetReviewRequestThunk(review_request_id))
        }
    },[review_request_id, dispatch])

    const lastReviewRequestId = useAppSelector<string>((state: RootState) => state.reviewTool.lastReviewRequestId)
    const reviewRequest = useAppSelector<ReviewRequestObject|undefined>((state: RootState) => state.reviewTool.reviewRequest)
    const userProfile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile)

    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(()=>{
        if(reviewRequest !== undefined && lastReviewRequestId !== reviewRequest.uuid){
            dispatch(getPlayerProfileThunk(reviewRequest.user.uuid))
            dispatch(updateLastReviewRequestId(reviewRequest.uuid))
            dispatch(newV2Review({label: "", uri: {mp4Src: reviewRequest.video.video.url, hlsSrc: reviewRequest.video.stream?.url || ""}, type: VideoType.submission} as VideoUpdate))
        }
    },[reviewRequest, lastReviewRequestId, dispatch])

    useEffect(()=> {
        if(userProfile){
            //can add color if saved in background. default is white.
            updateIntroBackgroundByHandle(userProfile.handle)
        }
    },[userProfile])


    return (
        <div className="full-screen-review-tool">
            <TitleBar viewMode={props.viewMode}/>
            <SubmissionDetails/>
            { props.viewMode === "ReviewTool" &&
                <>
                    <CoachCam/>
                    <CoachMainPane/>
                    <SidePanel/>
                </>
            }
            { props.viewMode === "FeedbackReview" &&
                <div id="moderator-main-pane">
                    {(reviewRequest && reviewRequest.feedback && reviewRequest.feedback.video && reviewRequest.feedback.video.url) ?
                        <HLSVideoPlayer className="video-main-pane-video" videoRef={videoRef} hlsSrc={reviewRequest.feedback.video.url} mp4Src={reviewRequest.feedback.video.url} controls/>
                        // <HLSVideoPlayer className="video-main-pane-video" videoRef={videoRef} hlsSrc={reviewRequest.feedback?.stream?.url || ""} mp4Src={reviewRequest.feedback.video.url} controls/>
                        :
                        <p>Video is processing, please wait.</p>
                    }
                </div>
            }
            { props.viewMode === "SubmissionReview" &&
                <div id="admin-main-pane">
                    {(reviewRequest && reviewRequest.video && reviewRequest.video.video && reviewRequest.video.video.url)  ?
                        <HLSVideoPlayer videoType={VideoType.fullReview} className="video-main-pane-video" videoRef={videoRef} hlsSrc={reviewRequest.video?.stream?.url || ""} mp4Src={reviewRequest.video.video.url} controls/>
                        :
                        <p>submission video url is null</p>
                    }
                </div>
            }
        </div>

    )

}
