import React, {useEffect, VideoHTMLAttributes} from 'react';
// import Hls from 'hls.js';
import {VideoType} from "../features/reviewtool/reviewtoolSlice";

export interface HLSVideoPlayerProps extends VideoHTMLAttributes<HTMLVideoElement> {
    hlsSrc: string; // URL of the HLS stream
    mp4Src: string; // Fallback URL of the MP4 video
    videoType?: VideoType
    videoRef: React.RefObject<HTMLVideoElement>
}

// const hlsConfig = {
//     // Buffer settings
//     maxBufferLength: 60,             // Maximum buffer length in seconds
//     // maxMaxBufferLength: 60,          // Maximum buffer length in seconds
//     // maxBufferSize: 60 * 1000 * 1000, // Maximum buffer size in bytes
//     maxBufferHole: 1.0,              // Maximum buffer hole in seconds

//     // Fragment load settings
//     maxFragLookUpTolerance: 0.4,     // Tolerance when seeking for a fragment, in seconds

//     // Live streaming settings
//     // liveSyncDurationCount: 3,        // Number of segments to keep in buffer for live streams
//     // liveMaxLatencyDurationCount: Infinity, // Maximum latency duration for live streams
//     // liveSyncDuration: undefined,     // Live sync duration in seconds (alternative to liveSyncDurationCount)
//     // liveMaxLatencyDuration: undefined, // Maximum latency duration in seconds (alternative to liveMaxLatencyDurationCount)

//     // ABR settings
//     enableWorker: true,              // Use web workers for ABR (Adaptive Bitrate) management
//     // enableSoftwareAES: true,         // Enable software AES decryption for DRM
//     // manifestLoadingTimeOut: 20000,   // Manifest loading timeout in milliseconds
//     // manifestLoadingMaxRetry: 4,      // Maximum number of retries for manifest loading
//     // manifestLoadingRetryDelay: 500,  // Delay between retries for manifest loading
//     // manifestLoadingMaxRetryTimeout: 64000, // Maximum retry timeout for manifest loading
//     // levelLoadingTimeOut: 20000,      // Level loading timeout in milliseconds
//     // levelLoadingMaxRetry: 4,         // Maximum number of retries for level loading
//     // levelLoadingRetryDelay: 500,     // Delay between retries for level loading
//     // levelLoadingMaxRetryTimeout: 64000, // Maximum retry timeout for level loading
//     // fragLoadingTimeOut: 20000,       // Fragment loading timeout in milliseconds
//     // fragLoadingMaxRetry: 6,          // Maximum number of retries for fragment loading
//     // fragLoadingRetryDelay: 500,      // Delay between retries for fragment loading
//     // fragLoadingMaxRetryTimeout: 64000, // Maximum retry timeout for fragment loading

//     // Low latency settings
//     lowLatencyMode: true,            // Enable low latency mode if your stream supports it
//     // startLevel: undefined,           // Level to start with (undefined means auto)

//     // Cap level on FPS drop
//     capLevelToPlayerSize: true,      // Cap the quality level to player size

//     // Logging
//     // debug: false,                    // Set to true to enable debug logs

//     // Additional settings
//     // initialLiveManifestSize: 1,      // Number of segments in the initial live manifest

//     // cause "Failed to set the 'duration' property on 'MediaSource': The 'updating' attribute
//     // is true on one or more of this MediaSource's SourceBuffers." error
//     // startFragPrefetch: true,         // Prefetch fragments ahead of time
//     // nudgeMaxRetry: 3,                // Maximum number of nudge retries
// };


export const HLSVideoPlayer = (props: HLSVideoPlayerProps): React.ReactElement => {
    const { hlsSrc, mp4Src, videoRef, videoType, ...rest } = props;

    const pauseVideo: boolean =
        videoType !== undefined
        && videoType !== VideoType.recordedClip
        && videoType !== VideoType.recordedIntro
        && videoType !== VideoType.recordedSummary

    console.log('pauseVideo', pauseVideo)

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        // Force MP4 playback
        video.src = mp4Src;

        console.log("Forcing playback with: mp4Src");

        video.addEventListener("loadedmetadata", () => {
            video.play()
                .then(() => {
                    if (pauseVideo) {
                        video.pause();
                    }
                })
                .catch((error) =>
                    console.error("Error playing the video:", error)
                );
        });

        return () => {
            video.pause();
            video.removeAttribute("src"); // Clear the source
            video.load();
        };

        // let hls: Hls;

        // if (hlsSrc !== "" && Hls.isSupported()) {
        //     hls = new Hls(hlsConfig);
        //     // hls = new Hls();
        //     hls.loadSource(hlsSrc);
        //     hls.attachMedia(video);
        //     hls.on(Hls.Events.MANIFEST_PARSED, () => {
        //         console.log("playing with: hls (supported)")
        //         video.play().then(()=>{if(pauseVideo){video.pause()}}).catch(error => console.error('Error playing the video:', error));
        //     });
        // } else {
        //     video.src = hlsSrc !== "" && video.canPlayType('application/vnd.apple.mpegurl') ? hlsSrc : mp4Src;
        //     console.log("playing with: ", hlsSrc !== "" && video.canPlayType('application/vnd.apple.mpegurl') ? "hlsSrc (application/vnd.apple.mpegurl)" : "mp4Src" )
        //     video.addEventListener('loadedmetadata', () => {
        //         video.play().then(()=>{ if(pauseVideo){video.pause()}}).catch(error => console.error('Error playing the video:', error));
        //     });
        // }


        // return () => {
        //     if (hls && hlsSrc !== ""){
        //         hls.destroy();
        //     }else{
        //         video.pause()
        //         video.removeAttribute('src'); // empty source
        //         video.load()
        //     }
        // };
    }, [hlsSrc, mp4Src, videoRef, videoType, pauseVideo]);

    return (<>
            <video ref={videoRef} {...rest}/>
    </>)
};
